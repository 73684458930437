@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,300italic,400italic');
@import "../../fontawesome/scss/fontawesome.scss";
@import "../../fontawesome/scss/light.scss";
@import "../../fontawesome/scss/brands.scss";

/*
	Astral by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

@include breakpoints((
        xlarge: (1281px, 1680px),
        large: (981px, 1280px),
        medium: (737px, 980px),
        small: (361px, 736px),
        xsmall: (null, 360px)
));

// Reset.
// Based on meyerweb.com/eric/tools/css/reset (v2.0 | 20110126 | License: public domain)

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input, select, textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

/* Row */

.row {
  @include html-grid((1.25em, 1.25em));

  @include breakpoint('<=xlarge') {
    @include html-grid((1.25em, 1.25em), 'xlarge');
  }

  @include breakpoint('<=large') {
    @include html-grid((1.25em, 1.25em), 'large');
  }

  @include breakpoint('<=medium') {
    @include html-grid((1.25em, 1.25em), 'medium');
  }

  @include breakpoint('<=small') {
    @include html-grid((1.25em, 1.25em), 'small');
  }
}

/* Basic */

// Set box model to border-box.
// Based on css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-size: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url('../../images/style/bg.jpg?as=webp') no-repeat top center;
  // overflow-y: scroll;

  // Stops initial animations until page loads.
  &.is-preload {
    *, *:before, *:after {
      @include vendor('animation', 'none !important');
      @include vendor('transition', 'none !important');
    }
  }

}

body, input, textarea, select {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #777777;
  font-size: 20pt;
  line-height: 1.75em;

  @include breakpoint('<=xlarge') {
    font-size: 15pt;
  }

  @include breakpoint('<=large') {
    font-size: 14pt;
  }

  @include breakpoint('<=small') {
    font-size: 12pt;
  }

  @include breakpoint('<=xsmall') {
    font-size: 11pt;
  }
}

strong, b, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #363636;
}

h1 {
  font-size: 2.4em;
  letter-spacing: -0.015em;
}

h2 {
  font-size: 1.8em;
  letter-spacing: -0.015em;
}

h3, h4, h5, h6 {
  font-size: 1.25em;
  letter-spacing: -0.015em;
}

@include breakpoint('<=small') {
  h1 {
    font-size: 1.75em;
  }

  h2 {
    font-size: 1.375em;
  }

  h3, h4, h5, h6 {
    font-size: 1em;
  }
}

blockquote {
  border-left: solid 0.5em #ddd;
  padding: 1em 0 1em 2em;
  font-style: italic;
}

em, i {
  font-style: italic;
}

hr {
  border: 0;
  border-top: solid 1px #ddd;
  padding: 1.5em 0 0 0;
  margin: 1.75em 0 0 0;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

br.clear {
  clear: both;
}

p, ul, ol, dl, table, blockquote, form {
  margin-bottom: 2em;
}

/* Table */

table {
  width: 100%;

  &.default {
    tbody {
      tr {
        border-bottom: solid 1px #f4f4f4;
      }
    }

    td {
      padding: 0.5em 1em 0.5em 1em;
    }

    th {
      text-align: left;
      font-weight: 400;
      padding: 0.5em 1em 0.5em 1em;
    }

    thead {
      border-bottom: solid 2px #f4f4f4;
    }
  }
}

/* Form */

form {
  label {
    display: block;
    font-weight: 400;
    color: #363636;
    margin: 0 0 1em 0;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  textarea {
    -webkit-appearance: none;
    border: 0;
    background: #f4f4f4;
    padding: 0.75em;
    width: 100%;
    @include vendor('transition', 'background-color .25s ease-in-out');

    &:focus {
      background: #f8f8f8;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select {
    line-height: 1.35em;
  }

  ::-webkit-input-placeholder {
    color: #999;
  }

  :-moz-placeholder {
    color: #999;
  }

  ::-moz-placeholder {
    color: #999;
  }

  :-ms-input-placeholder {
    color: #999;
  }

  label[for="check"] {
    margin: 0 0 .5em 0;
  }
}

/* Section/Article */

section, article {
  margin-bottom: 3em;

  > :last-child,
  &:last-child {
    margin-bottom: 0;
  }
}

header {
  margin: 0 0 1.5em 0;

  > p {
    margin: 0.5em 0 0 0;
    color: #aaa;
  }

  @include breakpoint('<=small') {
    margin: 0 0 1em 0;
  }
}

/* Image */

.image {
  display: inline-block;

  img {
    display: block;
    width: 100%;
  }

  &.fit {
    display: block;
    width: 100%;
  }

  &.featured {
    display: block;
    width: 100%;
    margin: 0 0 2em 0;
  }

  &.left {
    float: left;
    margin: 0 2em 2em 0;
  }

  &.centered {
    display: block;
    margin: 0 0 2em 0;

    img {
      margin: 0 auto;
      width: auto;
    }
  }
}

/* Button */

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  @include vendor('transition', 'background-color .25s ease-in-out');
  -webkit-appearance: none;
  display: inline-block;
  background-color: #222222;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0.7em 1.5em 0.7em 1.5em;

  &:hover {
    background-color: #333333;
  }

  &:active {
    background-color: #444444;
  }

  &.alt {
    background-color: #777777;

    &:hover {
      background-color: #888888;
    }

    &:active {
      background-color: #999999;
    }
  }

  &.small {
    font-size: 0.75em;
  }

  &.large {
    font-size: 1.25em;
    padding: 0.5em 1.25em 0.5em 1.25em;
  }

  &.xlarge {
    font-size: 1.5em;
    padding: 0.5em 1.25em 0.5em 1.25em;
  }

  @include breakpoint('<=small') {
    width: 100%;
  }
}

/* List */

ul {
  list-style: disc;
  padding-left: 1em;

  li {
    padding-left: 0.5em;
  }

}

ol {
  list-style: decimal;
  padding-left: 1.25em;

  li {
    padding-left: 0.25em;
  }
}

/* Actions */

ul.actions {
  list-style: none;
  padding-left: 0;

  li {
    display: inline-block;
    padding-left: 0;
    margin: 0 0 0 0.5em;

    &:first-child {
      margin-left: 0;
    }
  }

  @include breakpoint('<=small') {
    li {
      display: block;
      margin: 0.75em 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/* Icons */

.icon {
  position: relative;
  text-decoration: none;

  &:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    //noinspection CssNoGenericFontName
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    text-transform: none !important;
  }

  > .label {
    display: none;
  }

  &.brand:before {
    //noinspection CssNoGenericFontName
    font-family: "Font Awesome 5 Brands";
    font-weight: normal;

  }
}

/* Nav */

#nav {
  text-align: center;
  height: 4.25em;
  cursor: default;

  a {
    position: relative;
    display: inline-block;
    color: #ffffff;
    width: 1em;
    height: 1em;
    line-height: 0.9em;
    font-size: 2.5em;
    margin: 0 0.25em 0 0.25em;
    opacity: 0.35;
    outline: 0;
    @include vendor('transition', 'opacity .25s ease-in-out');

    @include breakpoint('<=small') {
      & {
        margin: 0 .10em;
      }
    }

    &.icon:before {
      padding-right: 0;
    }

    &:before {
      font-size: 0.8em;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -0.75em;
      margin-left: -0.5em;
      border-bottom: solid 0em #ffffff;
      border-left: solid 0.5em transparent;
      border-right: solid 0.5em transparent;
      @include vendor('transition', 'border-bottom-width .25s ease-in-out');
    }

    span {
      display: block;
      position: absolute;
      background: #222222;
      color: #ffffff;
      top: -2.75em;
      font-size: 0.3em;
      height: 2.25em;
      line-height: 2.25em;
      left: 50%;
      opacity: 0;
      @include vendor('transition', 'opacity .25s ease-in-out');
      // Labels not wide enough? Increase its width below and set margin-left to ((width / 2) * -1)
      width: 5.5em;
      margin-left: -2.75em;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -0.4em;
        left: 50%;
        margin-left: -0.6em;
        border-top: solid 0.6em #222222;
        border-left: solid 0.6em transparent;
        border-right: solid 0.6em transparent;
      }
    }

    &:hover {
      opacity: 1.0;

      span {
        opacity: 1.0;
      }
    }

    &.active {
      opacity: 1.0;

      &:after {
        border-bottom-width: 0.5em;
      }
    }
  }

  @include breakpoint('<=medium') {
    a {
      span {
        display: none;
      }
    }
  }
}

/* Wrapper */

#wrapper {
  width: 50em;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100%;
  padding: 4em 0;
  @include vendor('display', 'flex');
  @include vendor('flex-direction', 'column');
  @include vendor('align-items', 'center');
  @include vendor('justify-content', 'center');
  @include vendor('transition', 'opacity 1s ease-in-out');

  body.is-preload & {
    opacity: 0;
  }

  @include breakpoint('<=large') {
    padding: 3em 0;
    max-width: calc(100% - 6em);
  }

  @include breakpoint('<=medium') {
    padding: 1.5em 0;
    max-width: calc(100% - 8em);
  }

  @include breakpoint('<=small') {
    padding: 1em 0;
    max-width: calc(100% - 2em);
  }

  @include breakpoint('<=xsmall') {
    padding: 1em 0;
    max-width: 100%;
  }
}

/* Main */

#main {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  @include vendor('transition', (
          'min-height 0.5s ease-in-out',
          'max-height 0.5s ease-in-out'
  ));

  > .panel {
    @include vendor('transition', 'opacity 0.25s ease-in-out');
    margin-bottom: 0;
    position: relative;
    padding: 3.5em 2.5em 2.5em 2.5em;
    width: 100%;

    &.inactive {
      opacity: 0;
    }

    @include breakpoint('<=small') {
      padding: 2em 1.5em 1.5em 1.5em;
    }

    &.intro {
      padding: 0;
      height: 20em;
      @include vendor('display', 'flex');
      @include vendor('flex-direction', 'row');
      @include vendor('align-items', 'center');

      .extras {
        padding-top: 1em;
        font-size: 65%;
        line-height: 1.5;
      }

      .pic {
        text-decoration: none;
        position: relative;
        @include vendor('flex-grow', '0');
        @include vendor('flex-shrink', '0');
        width: 17em;
        height: 100%;

        img {
          position: relative;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          @include vendor('object-fit', 'cover');
          @include vendor('object-position', 'center');
        }

        .arrow {
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -1.375em;
          width: 2.75em;
          height: 2.75em;
          background: #000;
          background: rgba(0, 0, 0, 0.75);
          color: #ffffff;
          text-align: center;
          line-height: 2.75em;
          font-size: 1.5em;
          z-index: 1;
          @include vendor('transition', ('width .15s ease-in-out', 'padding-right .15s ease-in-out'));

          &:before {
            position: relative;
            padding-right: 0;
            top: 0.125em;
          }

          span {
            display: block;
            text-indent: -9999px;
          }
        }

        &:hover {
          .arrow {
            width: 3em;
            padding-right: 0.25em;
          }
        }
      }

      header {
        @include vendor('flex-grow', '1');
        @include vendor('flex-shrink', '1');
        padding: 3.5em 2.5em;
        margin-bottom: 0;
        width: 100%;

        h1 {
          line-height: 1.25em;
          margin-bottom: 0;
        }

        p {
          letter-spacing: -0.015em;
          font-size: 1.25em;
          margin: 0.25em 0 0 0;
        }
      }

      @include breakpoint('<=small') {
        @include vendor('flex-direction', 'column');
        height: auto;

        .pic {
          height: 20em;
          width: 100%;
        }

        header {
          padding: 2.75em 2em 2.5em 2em;
          text-align: center;

          p {
            font-size: 1em;
            margin: 0.25em 0 0 0;
          }
        }
      }
    }
  }
}

/* About */
#about {
  p {
    font-size: 20px;
    line-height: 2;
    margin-bottom: 1em;
  }

  header:nth-child(n+2) {
    margin-top: 1em;
  }

  img {
    display: block;
    width: 100%;

    border: 0;
    background: padding-box rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 40px;
  }
}

/* Talks */

.talk-description {
  & {
    margin-top: 0;
  }

  p.caption {
    font-size: .75em;
    line-height: 2;

    @include breakpoint('<=small') {
      & {
        font-size: 100%
      }
    }
  }
}

.talk {
  position: relative;
  width: 100%;
  height: fit-content;

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(31, 31, 31, 0.8);

    border: 0;
    border-radius: 6px;

    .text {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;

      .icon {
        font-size: 300%;
        display: block;
        padding-bottom: .25em;
      }
    }
  }

  img {
    display: block;
    width: 100%;

    border: 0;
    background: padding-box rgba(0, 0, 0, 0.1);
    margin: 0;
    padding: 0;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 5px 40px;
  }

  @include breakpoint('<=small') {
    .overlay {
      opacity: 1;
    }
  }
}

/* Footer */

#footer {
  color: rgba(255, 255, 255, 0.45);
  text-align: center;
  padding: 2em 0 0 0;
  font-size: 0.75em;

  a {
    color: #ddd;
    color: rgba(255, 255, 255, 0.65);
    @include vendor('transition', 'color .25s ease-in-out');

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }

  .social {
    > a {
      padding-left: 0.25em;
      padding-right: 0.25em;
    }
  }

  .copyright {
    list-style: none;
    padding-left: 0;

    li {
      display: inline-block;
      padding-left: 1em;
      margin-left: 1em;
      border-left: solid 1px rgba(255, 255, 255, 0.25);
      line-height: 1;

      &:first-child {
        padding-left: 0;
        margin-left: 0;
        border-left: 0;
      }
    }
  }

  @include breakpoint('<=small') {
    .copyright {
      li {
        padding-left: 0.5em;
        margin-left: 0.5em;
      }
    }
  }
}
